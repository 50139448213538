import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adi-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Input() alertType: string | undefined;
  @Input() message: string | undefined;
  @Output() closeAlert = new EventEmitter();
  @Input() customClass: string | undefined;

  close() {
    this.closeAlert.emit();
  }
}
