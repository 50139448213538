<div
  [ngClass]="{
    'alert-success': alertType === 'success',
    'alert-error': alertType === 'error',
    removeConnectionPopup: customClass === 'removeConnectionPopup',
    createUserClass: customClass === 'createUserClass',
    userCreatedPopup: customClass === 'userCreatedPopup'
  }"
  class="alert"
  role="alert"
>
  @if (alertType === 'success') {
    <img
      src="/assets/images/success-tick.svg"
      alt="success message"
      class="successicon"
    />
    <span
      [innerHTML]="message"
      class="successmessage"
      [ngClass]="{ userCreatedPopupLink: customClass === 'userCreatedPopup' }"
    ></span>
  }
  @if (alertType === 'error') {
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40232 3.34432C7.77785 2.9697 8.28681 2.7596 8.81724 2.76025L15.1967 2.768C15.7272 2.76865 16.2356 2.97998 16.6102 3.35551L21.1157 7.87197C21.4904 8.2475 21.7004 8.75647 21.6998 9.2869L21.692 15.6664C21.6914 16.1968 21.4801 16.7053 21.1045 17.0799L16.5881 21.5854C16.2125 21.96 15.7036 22.1701 15.1732 22.1695L8.79366 22.1617C8.26323 22.1611 7.75478 21.9497 7.38016 21.5742L2.87466 17.0577C2.50004 16.6822 2.28995 16.1732 2.29059 15.6428L2.29835 9.26332C2.29899 8.73288 2.51032 8.22443 2.88585 7.84982L7.40232 3.34432Z"
        fill="#E6062A"
      />
      <rect x="11.2949" y="7.46484" width="1.4" height="6.98871" fill="white" />
      <rect x="11.2949" y="15.5586" width="1.4" height="1.32321" fill="white" />
    </svg>
    <span>{{ message }}</span>
  }

  @if (alertType === 'success') {
    <img
      src="/assets/images/close-black-icon.svg"
      alt="close icon"
      (click)="close()"
      class="close"
    />
  }
  @if (alertType === 'error') {
    <img
      src="/assets/images/close-black-icon.svg"
      alt="close icon"
      (click)="close()"
      class="close"
    />
  }
</div>
